// App.js
import React, { useState, useRef } from 'react';
import JSZip from 'jszip';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

function App() {
  const [stampImage, setStampImage] = useState(null);
  const [imagesToStamp, setImagesToStamp] = useState([]);
  const [stampedImages, setStampedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);

  const handleStampImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setStampImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleImagesToStampChange = (e) => {
    const files = e.target.files;
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        imagesArray.push(reader.result);
      };
      reader.readAsDataURL(files[i]);
    }

    setImagesToStamp(imagesArray);
    setStampedImages([]); // Clear stamped images on new file upload
  };

  const handleStamp = () => {
    setLoading(true)
    const zip = new JSZip();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const stampImg = new Image();
    stampImg.src = stampImage;

    const promises = [];
    const stampedImagesArray = [];

    imagesToStamp.forEach((image, index) => {
      const img = new Image();
      img.src = image;
      const promise = new Promise((resolve) => {
        img.onload = () => {
          const maxDimension = Math.max(img.width, img.height);
          canvas.width = maxDimension;
          canvas.height = maxDimension;

          const cropX = img.width > img.height ? (img.width - img.height) / 2 : 0;
          const cropY = img.height > img.width ? (img.height - img.width) / 2 : 0;
          const cropSize = Math.min(img.width, img.height);

          ctx.drawImage(img, cropX, cropY, cropSize, cropSize, 0, 0, maxDimension, maxDimension);
          ctx.drawImage(stampImg, 0, 0, maxDimension, maxDimension);

          const stampedDataURL = canvas.toDataURL('image/png');
          stampedImagesArray.push(
            <Col key={index} className="mb-4">
              <Card>
                <Card.Img variant="top" src={stampedDataURL} />
              </Card>
            </Col>
          );

          canvas.toBlob((blob) => {
            zip.file(`stamped_image_${index + 1}.png`, blob);
            resolve();
          });
        };
      });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      setStampedImages(stampedImagesArray);
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = 'stamped_images.zip';
        link.click();
        setLoading(false)

      });
    });
  };

  return (
    <Container className="mt-5" dir='rtl' style={{
      
    }}>
      <h1 className="mb-4">تختيم صور بومباستك</h1>

      <Row className="mb-4">
        <Col md={6}>
          <label htmlFor="stampImage" className="form-label">
            اختر صورة الختم
          </label>
          <input
            type="file"
            accept="image/*"
            id="stampImage"
            className="form-control"
            onChange={handleStampImageChange}
          />
        </Col>
        <Col md={6}>
          <label htmlFor="imagesToStamp" className="form-label">
            اختر الصور التي سيتم ختمها
          </label>
          <input
            type="file"
            accept="image/*"
            id="imagesToStamp"
            className="form-control"
            multiple
            onChange={handleImagesToStampChange}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Button variant="primary" onClick={handleStamp} disabled={loading}>
           {
            loading?"جار التختيم":" البدء بالتختيم"
           }
          </Button>
        </Col>
      </Row>

      {stampedImages.length > 0 && (
        <Row className="mb-4">
          <Col>
            <h3>معاينة الصور المختومة</h3>
            <Row xs={1} md={2} lg={3} className="g-4">
              {stampedImages}
            </Row>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
